<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="class_add">
        <b-row>
          <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="classes"
                rules="required"
            >
              <v-select
                  v-model="class_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="classes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Institution </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="wings"
                rules="required" >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="wings"
                  v-model="wing_id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select sections </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="sections"
                rules="required" >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="sections"
                  v-model="section_ids"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select academic year </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="academic year"
                rules="required" >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="year"
                  :options="academic_years"
                  v-model="academic_year_id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BForm, BRow, BCol, BButton,BCardText
} from 'bootstrap-vue'
import {
  required
} from '@validations'
import apiCall from "@/libs/axios";

export default {
  name:'wingSectionAdd',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardText
  },
  data() {
    return {
      name: '',
      required,
      classes:[],
      wings:[],
      sections:[],
      academic_years:[],
      class_id: {},
      wing_id: {},
      section_ids:[],
      academic_year_id:{},
    }
  },
  created(){
    this.getAll();
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData();
          let sections=this.section_ids.map(item=>item.id);
          data.append('classes_id',this.class_id.id);
          data.append('academic_year_id',this.academic_year_id.id);
          data.append('sections',JSON.stringify(sections));
          apiCall.post(`/wing/section/ac/year/class/store${this.wing_id.id}`,data).then((response)=>{
            if (response.data.status ==='error'){
              this.$toaster.error(response.data.message);
            }else{
              this.$toaster.success(response.data.message);
              this.$refs.class_add.reset();
              this.section_ids=[];
              this.wing_id= {};
              this.academic_year_id={};
              this.class_id={};
              this.$refs.simpleRules.reset();
            }
          }).catch((error)=>{
            if (error.response.status ==422) this.$toaster.error(error.response.data.errors);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    async getAll(){
      await apiCall.get('/get/active/c/ay/w/sections').then((response)=>{
          let data = response.data;
          this.classes=data.classes;
          this.sections=data.sections;
          this.academic_years=data.academic_years;
      }).catch(()=>{
        this.classes=[];
        this.wings=[];
        this.sections=[];
        this.academic_years=[];
      });
    },
  },
  watch:{
    class_id(){
      this.wing_id={};
      this.wings=this.class_id.wings;
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
